import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import Share from "@material-ui/icons/Share";
import Functions from "@material-ui/icons/Functions";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

class ProductSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h5 className={classes.description}>
              As a holder of ADA coins, you can join with other coin holders to participate in the validation "mining" of blocks on
              the Cardano Blockchain. This is the "Proof of Stake" protocol that sets Cardano apart from Etherium and BitCoin. 
              By being a "Stakeholder", you can delegate this attribute to a Stakepool and share in the rewards without setting up a server.
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          {/*
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Products"
                description="We are offering a Cardano Staking Pool to validate transactions on the blockchain."
                icon={Apps}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Services"
                description="We provide engineering services to support the technical infrastructure needs to support Etherium and Cardano based blockchains."
                icon={Share}
                iconColor="success"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Development"
                description="In development are new dApps and APIs for the EVM and Cardano. We are writing Smart Contracts with Solidity and Plutus."
                icon={Functions}
                iconColor="danger"
                vertical
              />
            </GridItem>
          </GridContainer>
          */}
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(ProductSection);
